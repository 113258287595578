import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { PropertyLinkInput } from "@/types";

interface AssetUnlinkPropertiesResponse {
  assetUuid: string;
  propertyName: string;
}

export default function (toAssetUuid: string, links: PropertyLinkInput[]): Promise<AssetUnlinkPropertiesResponse[]> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($toAssetUuid: ID!, $links: [AssetPropertyPairInput!]!) {
          assetUnlinkProperties(toAssetUuid: $toAssetUuid, links: $links) {
            assetUuid
            propertyName
          }
        }
      `,
      variables: {
        toAssetUuid,
        links
      }
    })
    .then(response => {
      return response.data.assetUnlinkProperties;
    });
}
